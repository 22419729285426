import React from 'react';
import Main from './Main';
import './App.css';

// gif - fix pot moving
// add styles to thanks.html page
// file cleanup
// form submit without filling anything in
// new commits

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
