import React, { Component } from 'react';
import './insta.css';

class Home extends Component {
	render() {
		return (
			<div className="insta-container">
	      <div className="container">
	        <p className="follow">
	          Follow us on Instagram @pottedpgh
	        </p>
	      </div>
			</div>
    )
  }
}
export default Home;
